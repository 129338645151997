<template>
    <CContainer>
      <CRow class="justify-content-center">
        <h4>{{ $t('menuDescription') }}</h4>
      </CRow>
      <br>
      <br>
      <CRow class="justify-content-center">
        <CCol v-for="(item, index) in images" :key="index" md="8" lg="8" class="d-flex justify-content-center mb-4">
          <CCard class="card-style">
            <div class="card-content">
              <img :src="'/img/helpSidebar/' + item.src" :alt="`Image ${index + 1}`" class="card-image"/>
              <CCardBody class="card-description">
                <h5>{{ item.title }}</h5>
                <CCardText class="text-left">{{ item.description }}</CCardText>
              </CCardBody>
            </div>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
</template>
  
  <script>
  import { CContainer, CRow, CCol, CCard, CCardBody, CCardText } from '@coreui/vue';
  
  export default {
    name: 'SidebarHelp',
    components: {
      CContainer,
      CRow,
      CCol,
      CCard,
      CCardBody,
      CCardText
    },
    data() {
      return {
        images: [
          { src: '1.png', title: this.$t('reporttxt'), description: this.$t('menu.newReportMenu') },
          { src: 'sales.png', title: this.$t('salesTxt'), description: this.$t('menu.salesMenu') },
          { src: 'inventory.png', title: this.$t('inventoryTxt'), description: this.$t('menu.inventoryMenu') },
          { src: 'tax.png', title: this.$t('taxTxt'), description: this.$t('menu.taxMenu') },
          { src: 'staff.png', title: this.$t('staffTxt'), description: this.$t('menu.employeeMenu') },
          { src: 'ordermachine.png', title: this.$t('orderMachine'), description: this.$t('menu.orderMachinesMenu') },
          { src: 'management.png', title: this.$t('management.txtManagement'), description: this.$t('menu.dataManagement') },
          { src: 'product.png', title: this.$t('productDetail'), description: this.$t('menu.productDetailsMenu') },
          { src: 'warehouse.png', title: this.$t('warehouseTxt'), description: this.$t('menu.inventoryManagementMenu') },
          { src: '20.png', title: this.$t('management.shopBranch'), description: this.$t('menu.storeBranchMenu') },
          { src: 'selfOrder.png', title: this.$t('selfService'), description: this.$t('menu.selfOrderingMenu') },
          { src: 'member.png', title: this.$t('member.txtmember'), description: this.$t('menu.membersMenu') },
          { src: 'setting.png', title: this.$t('setting'), description: this.$t('menu.settingsMenu') },
        ]
      };
    },
    watch: {
      '$i18n.locale': {
        handler() {
          this.updateDescriptions();
        },
        immediate: true
      }
    },
    methods: {
      updateDescriptions() {
        this.images = [
          { src: '1.png', title: this.$t('reporttxt'), description: this.$t('menu.newReportMenu') },
          { src: 'sales.png', title: this.$t('salesTxt'), description: this.$t('menu.salesMenu') },
          { src: 'inventory.png', title: this.$t('inventoryTxt'), description: this.$t('menu.inventoryMenu') },
          { src: 'tax.png', title: this.$t('taxTxt'), description: this.$t('menu.taxMenu') },
          { src: 'staff.png', title: this.$t('staffTxt'), description: this.$t('menu.employeeMenu') },
          { src: 'ordermachine.png', title: this.$t('orderMachine'), description: this.$t('menu.orderMachinesMenu') },
          { src: 'management.png', title: this.$t('management.txtManagement'), description: this.$t('menu.dataManagement') },
          { src: 'product.png', title: this.$t('productDetail'), description: this.$t('menu.productDetailsMenu') },
          { src: 'warehouse.png', title: this.$t('warehouseTxt'), description: this.$t('menu.inventoryManagementMenu') },
          { src: '20.png', title: this.$t('management.shopBranch'), description: this.$t('menu.storeBranchMenu') },
          { src: 'selfOrder.png', title: this.$t('selfService'), description: this.$t('menu.selfOrderingMenu') },
          { src: 'member.png', title: this.$t('member.txtmember'), description: this.$t('menu.membersMenu') },
          { src: 'setting.png', title: this.$t('setting'), description: this.$t('menu.settingsMenu') },
        ];
      }
    }
  };
  </script>
  
  <style scoped>
  .card-style {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-color: #f8f9fa;
    padding: 15px;
    width: 100%;
  }
  
  .mb-4 {
    margin-bottom: 20px;
  }
  
  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .card-image {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
  }
  
  .card-description {
    width: 100%;
    text-align: center;
  }
  
  .card-description h5 {
    margin: 0 0 10px;
    font-size: 18px;
    color: #333;
  }
  
  .card-text {
    font-size: 14px;
    color: #555;
    text-align: left;
  }
  </style>
  