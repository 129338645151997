import { render, staticRenderFns } from "./SidebarHelp.vue?vue&type=template&id=50d12a44&scoped=true&"
import script from "./SidebarHelp.vue?vue&type=script&lang=js&"
export * from "./SidebarHelp.vue?vue&type=script&lang=js&"
import style0 from "./SidebarHelp.vue?vue&type=style&index=0&id=50d12a44&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50d12a44",
  null
  
)

export default component.exports